import { useMemo } from 'react';

import useSWR from 'swr';

import { MetaData } from '@hultafors/shared/helpers';
import { ProductListResponse } from '@hultafors/shared/types';

import { PAGE_SIZE_S } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  GlobalFields,
  HellbergProduct,
  PageNotFoundFragment,
} from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { InformationHero } from '../InformationHero/InformationHero';
import { Loader } from '../Loader/Loader';
import { Page } from '../Page/Page';
import { Products } from '../Products/Products';
import { Section } from '../Section/Section';

interface PageNotFoundContentProps extends GlobalFields {
  content: PageNotFoundFragment;
}

export const PageNotFoundContent: React.FC<PageNotFoundContentProps> = ({
  content,
}) => {
  const { shared, siteLocale } = useGlobal();

  const metadata: MetaData = useMemo(() => {
    return {
      description: content?.heroDescription || '',
      title: content?.heroTitle || 'Page Not Found!',
    };
  }, [content]);

  const path = '/api/productslist';

  const search: string | undefined = useMemo(() => {
    return content?.products
      ? new URLSearchParams({
          lang: siteLocale.parttrapLanguage ?? '',
          market: siteLocale.parttrapMarket ?? '',
          pageSize: `${PAGE_SIZE_S}`,
          productCatalogNodeId: content?.products,
          siteId: siteLocale.parttrapSiteId ?? '',
        }).toString()
      : undefined;
  }, [content]);

  const { data, isLoading } = useSWR<ProductListResponse<HellbergProduct>>(
    search && `${path}?${search}`,
    {
      revalidateOnMount: true,
    }
  );

  if (!content) {
    return null;
  }

  return (
    <Page metadata={metadata}>
      <InformationHero
        title={content?.heroTitle || ''}
        description={content?.heroDescription || ''}
        center
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Section>
          <Grid
            columns={12}
            columnGap={[
              { columnGap: 15 },
              { breakpoint: 'desktop', columnGap: 30 },
            ]}
          >
            <GridChild
              columnSpan={[
                { columns: 12 },
                { breakpoint: 'desktop', columns: 12 },
              ]}
            >
              <Grid
                columnGap={[
                  { columnGap: 15 },
                  { breakpoint: 'desktop', columnGap: 30 },
                ]}
                columns={[
                  { columns: 1 },
                  { breakpoint: 'mobileMax', columns: 2 },
                  { breakpoint: 'desktop', columns: 3 },
                ]}
              >
                <Products
                  products={data?.items || []}
                  rrpLabel={shared?.rrpLabel || ''}
                  rrpIncludingVatLabel={shared?.rrpIncludingVatLabel || ''}
                />
              </Grid>
            </GridChild>
          </Grid>
        </Section>
      )}
    </Page>
  );
};
