import { Paragraph } from '../Paragraph/Paragraph';

import {
  DownloadFileBoxStyled,
  StyledDownloadIcon,
  StyledFileIcon,
} from './DownloadFileBox.styled';

interface DownloadFileBoxProps {
  className?: string;
  url?: string;
  label?: string;
  filetype?: string;
  size?: number | string;
}

export const DownloadFileBox: React.FC<DownloadFileBoxProps> = ({
  className,
  url,
  label = '',
  filetype = '',
  size = '',
}) => {
  let formattedSize = `${size}`;
  if (!formattedSize?.toUpperCase().endsWith('B')) {
    const sizeNumber = parseInt(formattedSize, 10);
    const unit =
      sizeNumber > 1000000
        ? 'megabyte'
        : sizeNumber > 1000
        ? 'kilobyte'
        : 'byte';
    const fraction =
      sizeNumber > 1000000 ? 1000000 : sizeNumber > 1000 ? 1000 : 1;
    const fractionSize = sizeNumber / fraction;
    formattedSize = new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: fractionSize < 1 ? 2 : 0,
      minimumFractionDigits: 0,
      style: 'unit',
      unit,
    }).format(sizeNumber / fraction);
  }
  // Add zero-width space to allow line break after underscore
  const soft = label?.replace(/_/g, '_\u200b') ?? '';
  return (
    <DownloadFileBoxStyled data-test="DownloadFileBox" className={className}>
      <a href={url} target="_blank" rel="noreferrer">
        <StyledFileIcon
          aria-hidden={true}
          focusable={false}
          width={24}
          height={24}
        />
        <div className="TextWrapper">
          <p className="Label">{soft}</p>
          <Paragraph styleType="body10" className="Size">
            {`${filetype} ${!!size && formattedSize}`}
          </Paragraph>
        </div>

        <StyledDownloadIcon
          aria-hidden={true}
          focusable={false}
          width={24}
          height={24}
        />
      </a>
    </DownloadFileBoxStyled>
  );
};
