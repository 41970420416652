import dynamic from 'next/dynamic';
import styled from 'styled-components';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

const AccordionItem = dynamic(() =>
  import('../AccordionItem/AccordionItem').then((mod) => mod.AccordionItem),
);

export const FindRetailersListStyled = styled.div`
  margin-block-end: ${spacing.medium};

  .CollapseHeader {
    block-size: 72px;
    cursor: pointer;
    line-height: ${lineHeights.header3};
    border-block-end: 1px solid ${colors.gray4};
    transition: border-color 0.4s ease;

    &.isOpen {
      border-color: rgb(0 0 0 / 0%);
    }

    h2 {
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body16};
      color: ${colors.black};
    }

    .SubLabel {
      font-size: ${fontSizes.body12};
      color: ${colors.gray0};
      line-height: ${lineHeights.body12};
    }
  }

  .CollapseContent {
    padding: 0;
    background-color: ${colors.gray5};

    p {
      margin-block-end: ${spacing.small};
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body16};
    }
  }
`;

export const StoreSubTitle = styled.span`
  font-size: ${fontSizes.body12};
  color: ${colors.gray0};
  width: 100%;
  display: inline-block;
  margin-block-start: 0.15rem;
`;

export const StoreAccordionItem = styled(AccordionItem)`
  padding-block: ${spacing.small};
`;
