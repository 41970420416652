import { FC, useEffect, useState } from 'react';

import { useWindowSize } from 'react-use';

import { breakpointsRaw } from '@hultafors/shared/constants';
import { ImageBasicFragment } from '@hultafors/shared/types';

import { HeroBlockFragment } from '@hultafors/hellberg/types';

import Logo from '../../svg/logo.svg';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { H1 } from '../H1/H1';
import { H2 } from '../H2/H2';
import { HeroImage } from '../HeroImage/HeroImage';

import {
  CenterWrapper,
  ContentWrapper,
  Description,
  DescriptionBox,
  HeroBlockContent,
  HeroBlockStyled,
  HeroButtonWrapper,
  HeroIcon,
  HeroTitle,
  HeroVideo,
  VideoInner,
  VideoStyled,
} from './HeroBlock.styled';

interface HeroBlockProps extends HeroBlockFragment {
  icon?: ImageBasicFragment;
  priority?: boolean;
  isInSlider?: boolean;
}

export const HeroBlock: FC<HeroBlockProps> = ({
  title,
  description,
  border,
  ctaColor,
  firstCtaText: ctaTitle1,
  secondCtaText: ctaTitle2,
  firstCtaLink: ctaUrl1,
  secondCtaLink: ctaUrl2,
  mobileImage,
  tabletImage,
  desktopImage,
  icon,
  isInSlider,
  priority,
  video,
  useVideo,
}) => {
  const [playbackId, setPlaybackId] = useState<string | undefined>(
    video?.video?.muxPlaybackId ?? undefined
  );

  const [placeholder, setPlaceholder] = useState<string | undefined>(
    video?.blurUpThumb ?? undefined
  );

  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width > breakpointsRaw.tablet) {
      setPlaybackId(video?.video?.muxPlaybackId ?? undefined);
      setPlaceholder(video?.blurUpThumb ?? undefined);
    }
  }, [width]);

  let TitleComponent = H2;

  if (priority) {
    TitleComponent = H1;
  }

  return (
    <HeroBlockStyled
      $image={!!(desktopImage || tabletImage || mobileImage || useVideo)}
    >
      {!useVideo && (
        <HeroImage
          mobile={mobileImage}
          tablet={tabletImage}
          desktop={desktopImage}
          priority={priority}
        />
      )}
      {useVideo && playbackId && (
        <VideoStyled>
          <VideoInner>
            <HeroVideo
              playbackId={playbackId}
              placeholder={placeholder}
              style={{
                height: '100%',
                inset: 0,
                position: 'absolute',
                width: '100%',
              }}
            />
          </VideoInner>
        </VideoStyled>
      )}
      <HeroBlockContent>
        <ContentWrapper
          $borderColor={border && ctaColor?.hex}
          $isInSlider={isInSlider}
        >
          <CenterWrapper>
            {icon && (
              <HeroIcon color={ctaColor?.hex}>
                <Logo
                  aria-hidden={true}
                  focusable={false}
                  width={200}
                  height={200}
                />
              </HeroIcon>
            )}
            <HeroTitle as={TitleComponent}>{title}</HeroTitle>

            {description && (
              <DescriptionBox>
                <Description>{description}</Description>
              </DescriptionBox>
            )}
            {(ctaTitle1 || ctaTitle2) && (
              <HeroButtonWrapper
                $image={
                  !!(desktopImage || tabletImage || mobileImage || useVideo)
                }
              >
                <span>
                  {ctaTitle1 && ctaUrl1 && (
                    <ButtonLink
                      to={ctaUrl1 || ''}
                      className={`${ctaColor ? 'DatoColor' : 'Blue'}`}
                      color={ctaColor?.hex}
                    >
                      {ctaTitle1}
                    </ButtonLink>
                  )}
                  {ctaTitle2 && ctaUrl2 && (
                    <ButtonLink to={ctaUrl2} className="White">
                      {ctaTitle2}
                    </ButtonLink>
                  )}
                </span>
              </HeroButtonWrapper>
            )}
          </CenterWrapper>
        </ContentWrapper>
      </HeroBlockContent>
    </HeroBlockStyled>
  );
};
