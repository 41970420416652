import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

export const ContentBlockStyled = styled.div`
  .HeroContent {
    padding-block: 0;
    margin-block: ${spacing.medium} ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block: ${spacing.large} ${spacing.large};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    .HeroContent {
      padding-inline: ${spacing.medium} ${spacing.medium};
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    .HeroContent {
      padding-inline: ${spacing.medium} ${spacing.medium};
    }
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    .HeroContent {
      padding-inline: 0;
    }
  }

  .TextBoxText {
    padding-block-start: ${spacing.small};
    margin-block-end: ${spacing.regular};
  }
`;
