import { useMemo } from 'react';

import dynamic from 'next/dynamic';
import useSWR from 'swr';

import { ProductListResponse } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  HellbergProduct,
  HellbergProductDetails,
  ProductPageFragment,
} from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { ProductDetailData } from '../ProductDetailData/ProductDetailData';
import { ProductDetailInformation } from '../ProductDetailInformation/ProductDetailInformation';
import { ProductMoreInformation } from '../ProductMoreInformation/ProductMoreInformation';

import {
  ProductDetailViewStyled,
  StyledGridChild,
} from './ProductPageContent.styled';

const ProductSlider = dynamic(() =>
  import('../ProductSlider/ProductSlider').then((mod) => mod.ProductSlider)
);
const ProductVideo = dynamic(() =>
  import('../ProductVideo/ProductVideo').then((mod) => mod.ProductVideo)
);
const RelatedProductsBlock = dynamic(() =>
  import('../RelatedProductsBlock/RelatedProductsBlock').then(
    (mod) => mod.RelatedProductsBlock
  )
);
const SingleProductImage = dynamic(() =>
  import('../SingleProductImage/SingleProductImage').then(
    (mod) => mod.SingleProductImage
  )
);

interface ProductPageContentProps {
  content: ProductPageFragment;
  product: HellbergProductDetails;
}

export const ProductPageContent: React.FC<ProductPageContentProps> = ({
  content,
  product,
}) => {
  const { shared, siteLocale } = useGlobal();

  const { data } = useSWR<ProductListResponse<HellbergProduct>>(
    `/api/productrelationlist/${product.modelsId}?${new URLSearchParams({
      lang: siteLocale.parttrapLanguage ?? '',
      market: siteLocale.parttrapMarket ?? '',
      pageSize: '1',
      productId: `${product.id}`,
      siteId: siteLocale.parttrapSiteId ?? '',
    }).toString()}`,
    {
      revalidateOnMount: true,
    }
  );

  const model: HellbergProduct | undefined = useMemo(() => {
    return data?.items?.[0] || undefined;
  }, [data]);

  let appInformation = null;

  if (
    content?.productsToShow?.includes(product?.id) &&
    content?.appstoreBadge &&
    content?.googlePlayBadge &&
    content?.appstoreUrl &&
    content?.googlePlayUrl &&
    content?.appLinksHeader
  ) {
    appInformation = {
      appstoreBadge: content?.appstoreBadge,
      appstoreUrl: content?.appstoreUrl,
      googlePlayBadge: content?.googlePlayBadge,
      googlePlayUrl: content?.googlePlayUrl,
      header: content?.appLinksHeader,
      id: product?.id,
    };
  }

  const ProductImage = () => {
    if (product.images.length > 1) {
      return (
        <ProductSlider images={product.images} productName={product.name} />
      );
    }

    return (
      <SingleProductImage
        image={product.images[0]}
        productName={product.name}
      />
    );
  };

  const productVideoMapper = (videoId: string, index: number) => {
    if (videoId.includes('https')) {
      return null;
    }
    return <ProductVideo key={`Video-${index}`} videoId={videoId} />;
  };

  return (
    <>
      <ProductDetailViewStyled>
        <Grid
          autoFlow="dense"
          columns={[
            { columns: 4 },
            { breakpoint: 'desktopSmall', columns: 12 },
          ]}
        >
          <StyledGridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'desktopSmall', columns: 4, start: 9 },
            ]}
          >
            <ProductDetailData
              modelSku={model?.sku}
              modelSkuLabel={shared.skuLabel || ''}
              rrpLabel={shared.rrpLabel || ''}
              rrpIncludingVatLabel={shared.rrpIncludingVatLabel || ''}
              newLabel={shared.new || ''}
              isNew={product?.isNew}
              product={product}
            />
          </StyledGridChild>
          <StyledGridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'desktopSmall', columns: 7 },
            ]}
          >
            <ProductImage />
          </StyledGridChild>
          <StyledGridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'desktopSmall', columns: 4, start: 9 },
            ]}
          >
            <ProductDetailInformation
              usp={product.usp}
              overviewLabel={content.overview || ''}
              documentsLabel={content.documents || ''}
              detailsLabel={content.details || ''}
              // detailsLabel={content.details}
              intro={product.intro || ''}
              detailsInfo={product.details}
              // documents={documents}
              // Not in the raw data, this is requested in trello. Geto back to it when we have data from pim
              // materials={product.materials}
              // materialsHeader={content.materials}
              // materialsLinkText={pageContent.materials_link_page_text}
              // materialsLink={pageContent.materials_page_url}

              productForStorage={{ ...product, modelSku: model?.sku }}
              features={product.features}
              featuresLabel={content.featuresLabel || ''}
              appInformation={appInformation}
              retailersLabel={content.findRetailersLabel || ''}
              productId={product.id}
            />
          </StyledGridChild>
        </Grid>
      </ProductDetailViewStyled>
      <ProductMoreInformation
        snrList={product?.snrList}
        snrLabel={content.snrInfo || ''}
        technicalDataLabel={content.technicalData || ''}
        techList={product?.techList}
        contentFeatures={product?.contentFeatures}
        headerFeatures={content.featuresLabel || ''}
      />
      {product?.videos?.map(productVideoMapper)}
      {product.relatedProductsId && (
        <RelatedProductsBlock
          header={content.relatedProductsHeader || ''}
          productRelationListId={product.relatedProductsId}
          productId={product.id}
        />
      )}
    </>
  );
};
