import useSWR from 'swr';

import { ProductListResponse } from '@hultafors/shared/types';

import { PAGE_SIZE_S } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  HellbergProduct,
  RelatedProductsBlockFragment,
} from '@hultafors/hellberg/types';

import { PlugsGrid } from '../PlugsGrid/PlugsGrid';
import { Product } from '../Product/Product';

import { Header, StyledRelatedProducts } from './RelatedProductsBlock.styled';

interface RelatedProductsBlockProps
  extends Omit<RelatedProductsBlockFragment, 'id'> {
  productId?: string;
}

export const RelatedProductsBlock: React.FC<RelatedProductsBlockProps> = ({
  header,
  productRelationListId,
  productId,
}) => {
  const { shared, siteLocale } = useGlobal();

  const productListUrl = `/api/productslist?${new URLSearchParams({
    includeFilters: 'false',
    lang: siteLocale.parttrapLanguage ?? '',
    market: siteLocale.parttrapMarket ?? '',
    pageSize: `${PAGE_SIZE_S}`,
    productCatalogNodeId: `${productRelationListId}`,
    siteId: siteLocale.parttrapSiteId ?? '',
  }).toString()}`;

  const relationListUrl = `/api/productrelationlist/${productRelationListId}?${new URLSearchParams(
    {
      pageSize: `${PAGE_SIZE_S}`,
      productId: `${productId}`,
    }
  ).toString()}`;

  let listUrl: string | null = null;

  if (productId) {
    listUrl = relationListUrl;
  }

  if (!productId) {
    listUrl = productListUrl;
  }

  const { data } = useSWR<ProductListResponse<HellbergProduct>>(listUrl, {
    revalidateOnMount: true,
  });

  const productMapper = (product: HellbergProduct, i: number) => {
    return (
      <Product
        product={product}
        rrpLabel={shared.rrpLabel || ''}
        key={`Product-${i}`}
      />
    );
  };

  if (!data?.items?.length) {
    return null;
  }

  let totalItems = 3;

  if (data?.items?.length && data?.items?.length >= 3) {
    totalItems = data?.items.length;
  }

  return (
    <StyledRelatedProducts>
      <Header>{header}</Header>
      <PlugsGrid gap={100} total={totalItems}>
        {data?.items.map(productMapper)}
      </PlugsGrid>
    </StyledRelatedProducts>
  );
};
