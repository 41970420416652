import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { Section } from '../Section/Section';

export const HeroContent = styled(Section)`
  padding-block: 0;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block: ${spacing.large} ${spacing.large};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline: ${spacing.medium} ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-inline: ${spacing.medium} ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    padding-inline: 0;
  }
`;

export const MaintenanceBlockStyled = styled.div`
  background-color: ${colors.gray5};
  padding-block: ${spacing.medium} ${spacing.medium};
`;

export const StyledMarkdown = styled(Markdown).attrs({
  options: {
    forceBlock: true,
  },
})`
  padding-block-start: ${spacing.small};

  p {
    margin-block-end: ${spacing.regular};
  }
`;
