import { BlueInfoBoxFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';

import { BlueInfoBoxStyled, Content } from './BlueInfoBox.styled';

export const BlueInfoBox: React.FC<BlueInfoBoxFragment> = ({
  body,
  scrollId,
}) => {
  if (!body) {
    return null;
  }

  return (
    <BlueInfoBoxStyled {...(scrollId ? { id: scrollId } : {})}>
      <Content>
        <Markdown
          options={{
            overrides: {
              p: {
                component: HTag,
                props: {
                  styleType: [
                    { breakpoint: 'mobileMax', styleType: 'header1' },
                    { styleType: 'header3' },
                  ],
                  type: 'h2',
                },
              },
            },
          }}
        >
          {body}
        </Markdown>
      </Content>
    </BlueInfoBoxStyled>
  );
};
