import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

export const Title = styled(HTag).attrs({
  styleType: [
    { styleType: 'header2' },
    { breakpoint: 'desktop', styleType: 'header1' },
  ],
  type: 'h2',
})`
  padding-block-end: ${spacing.regular};
`;

export const StyledGridChild = styled(GridChild)`
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-block: ${spacing.medium};
  margin-inline: 0;
  padding-block: 0;
  padding-inline: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: 0;
    padding-inline: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    inline-size: 75%;
    margin: 0;
  }
`;

export const BigPlugStyled = styled.div`
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.medium};
  }
`;
