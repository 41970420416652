import Link from 'next/link';
import styled, { css } from 'styled-components';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  aspect-ratio: 1 / 1;
  background-color: ${colors.gray6};

  img {
    object-fit: contain;
  }
`;

export const ProductListItem = styled(Link)`
  text-decoration: none;
`;

export const ProductListViewStyled = styled.div`
  display: flex;
  width: 100%;
  padding-block: ${spacing.regular};
  padding-inline: 0;
  border-block-end: 1px solid ${colors.gray4};
  position: relative;

  &:last-of-type {
    border-block-end: none;
  }

  span {
    inline-size: 100%;
  }

  a {
    position: relative;
    display: flex;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  inset-inline-end: -12px;
  inset-block-start: ${spacing.small};
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;
  color: ${colors.hellBlue};

  &:hover {
    color: ${colors.hellBlueHover};
  }
`;

export const ProductName = styled(Paragraph).attrs({
  styleType: 'body16Medium',
})`
  display: block;
  margin-block: 0 ${spacing.xsmall};
  padding-inline-end: ${spacing.small};
`;

export const ProductInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ProductDetails = styled.div`
  margin-block-end: ${spacing.small};
`;

const productDetailStyle = css`
  display: block;
  width: 100%;

  ${ProductListItem}:hover & {
    color: ${colors.black};
  }
`;

export const ArtNo = styled(Paragraph).attrs({ styleType: 'body12' })`
  ${productDetailStyle};
  margin: 0;
  margin-block-end: ${spacing.xsmall};
  color: ${colors.gray0};
`;

export const ProductPrice = styled(Paragraph).attrs({ styleType: 'body12' })`
  ${productDetailStyle};
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-block-end: ${spacing.xsmall};
`;

export const PriceLabel = styled(Paragraph).attrs({ styleType: 'body12' })`
  margin-inline-start: ${spacing.tinier};
  color: ${colors.gray0};
`;
