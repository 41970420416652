import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import LoadingCircle from '../../svg/loading_circle.svg';

export const FeatureList = styled.ul`
  margin: 0;
  margin-block-end: ${spacing.medium};
  padding: 0;
  padding-inline-start: 1em;

  li {
    color: ${colors.black};
    font-style: normal;
    font-weight: ${fontWeights.fontRegular};
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-family: ${fontFamilies.fontRegular};
  }
`;

export const ProductDetailInformationStyled = styled.div`
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: 0;
    padding-inline-end: ${spacing.medium};
    padding-block-end: ${spacing.medium};
    margin-block-start: 0;
  }

  position: relative;

  h1 {
    padding-inline-end: ${spacing.regular};
  }

  .InfoFirstBlock {
    position: relative;
    margin-block-end: ${spacing.medium};

    .NewLabel {
      position: absolute;
      inset-block-start: -${spacing.regular};
      color: ${colors.hellSalmon};
    }
  }

  .Favorite {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
  }

  .Placeholder {
    block-size: ${spacing.regular};
  }

  .SizeGuideButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    inline-size: 100%;
    margin-block-end: ${spacing.small};

    .SizeGuideWrapper {
      display: flex;
      justify-content: flex-end;

      svg {
        margin-inline-start: ${spacing.xsmall};
        transition: all 0.3s ease;
      }

      &:hover {
        cursor: pointer;

        svg {
          transform: translateX(4px);
        }
      }
    }

    p {
      margin: 0;
      font-style: normal;
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.body14};
      line-height: ${lineHeights.body14};
    }
  }

  .ChooseSizeButton {
    button:first-of-type {
      margin-block-end: ${spacing.small};
    }

    button:last-of-type {
      margin-block-end: ${spacing.medium};
    }
  }

  .Materials {
    padding-block-start: ${spacing.medium};
    border-block-start: 1px solid ${colors.gray4};

    h3 {
      font-size: ${fontSizes.h3};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
      margin-block-end: ${spacing.regular};
    }

    img {
      block-size: 48px;
      inline-size: auto;
      max-inline-size: 100px;
      margin: 0 0 ${spacing.small} 0;
    }

    p {
      margin: 0;
    }

    .MaterialItem {
      margin-block-end: ${spacing.regular};
    }
  }

  .MaterialsLink {
    margin-block-start: ${spacing.regular};

    a {
      font-size: ${fontSizes.body14};
    }
  }

  .MaterialsLink:hover {
    &::after {
      transition: all 0.3s ease;
      transform: translateX(4px);
    }
  }
`;

export const RetailersLink = styled(Link)<{ $stretch?: boolean }>`
  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  svg {
    margin-inline-end: ${spacing.xsmall};
    color: ${colors.white};
  }

    background-repeat: no-repeat;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: var(--font-bold);
    text-decoration: none;
    cursor: pointer;
    transition: 0.1s;
    min-height: 56px;
    padding-block: 0;
    padding-inline: 32px;
    width: 100%;
    color: rgb(248 248 252);
    margin-block-end: 48px;
    background-color: ${colors.black};

    &:hover {
      background-color: ${colors.blackHover};
    }
`;

export const StyledLoadingCircle = styled(LoadingCircle)`
  color: ${colors.white};
  margin-inline-end: 0 !important;
`;
