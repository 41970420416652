import dynamic from 'next/dynamic';

import { Store } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/hellberg/hooks';

import {
  FindRetailersListStyled,
  StoreAccordionItem,
  StoreSubTitle,
} from './FindRetailersList.styled';

const Accordion = dynamic(() =>
  import('../Accordion/Accordion').then((mod) => mod.Accordion)
);
const StoreItem = dynamic(() =>
  import('../StoreItem/StoreItem').then((mod) => mod.StoreItem)
);

export interface FindRetailersListProps {
  stores: Store[];
  selectItem?(id: string): void;
  selectedItem?: Store;
  viewOnMap: string;
  storeAlsoSellsLabel: string;
  visitWebsiteLabel: string;
}

export const FindRetailersList: React.FC<FindRetailersListProps> = ({
  viewOnMap,
  storeAlsoSellsLabel,
  visitWebsiteLabel,
  stores,
}) => {
  const keyPrefix = 'Store';
  const { siteLocale } = useGlobal();
  const storeMapper = (item: Store) => {
    return (
      <StoreAccordionItem
        key={`Store-${item.id}`}
        arrowIcon
        label={
          <span>
            <span>{item.data.companyName}</span>
            <StoreSubTitle>{item.data.city}</StoreSubTitle>
          </span>
        }
      >
        <StoreItem
          coordinates={{
            lat: parseFloat(
              item.data.latitude ||
                `${siteLocale.defaultCoordinates?.latitude}` ||
                ''
            ),
            lng: parseFloat(
              item.data.longitude ||
                `${siteLocale.defaultCoordinates?.longitude}` ||
                ''
            ),
          }}
          label={item.data.companyName || ''}
          subLabel={item.data.city || ''}
          key={`${keyPrefix}-${item.id}`}
          keyPrefix={keyPrefix}
          id={item.id}
          address={item.data.address1 || ''}
          zipCode={item.data.zipCode || ''}
          city={item.data.city || ''}
          country={item.data.country || ''}
          mapLink={item.mapLink}
          view_on_map={viewOnMap}
          emailAddress={item.data.emailAddress || ''}
          phone={item.data.phone || ''}
          storeSells={item.storeSells}
          this_store_also_sells={storeAlsoSellsLabel}
          website={item.data.webSite || ''}
          visit_web_site={visitWebsiteLabel}
        />
      </StoreAccordionItem>
    );
  };
  return (
    <FindRetailersListStyled>
      {stores.length > 0 && (
        <Accordion allowZeroExpanded>{stores.map(storeMapper)}</Accordion>
      )}
    </FindRetailersListStyled>
  );
};
