import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { useGlobal } from '@hultafors/hellberg/hooks';
import { HellbergProductDetails } from '@hultafors/hellberg/types';

import { DownloadFileBox } from '../DownloadFileBox/DownloadFileBox';
import { Paragraph } from '../Paragraph/Paragraph';

import {
  DetailInfo,
  OverviewText,
  ProductInformationTabsStyled,
  Tab,
  Tabs,
} from './ProductInformationTabs.styled';

interface ProductInformationTabsProps {
  overviewLabel?: string;
  documentsLabel?: string;
  detailsLabel?: string;
  intro?: string;
  // documents: ProductDetailsApiDocument[];
  detailsInfo?: string;
  productId: string;
}

export const ProductInformationTabs: React.FC<ProductInformationTabsProps> = ({
  overviewLabel,
  intro,
  documentsLabel,
  detailsLabel,
  detailsInfo,
  productId,
}) => {
  const { siteLocale } = useGlobal();
  const [activeTab, setActiveTab] = useState(1);

  const getDocumentsKey = () => {
    const path = '/api/productDocuments';
    const params = new URLSearchParams({
      lang: siteLocale.parttrapLanguage ?? '',
      market: siteLocale.parttrapMarket ?? '',
      productId: `${productId}`,
      siteId: siteLocale.parttrapSiteId ?? '',
    }).toString();
    return [path, params.toString()].join('?');
  };

  const { data: docData, mutate: revalidateDocuments } =
    useSWR<HellbergProductDetails>(getDocumentsKey, {
      revalidateOnMount: true,
    });

  const documents = docData?.documents ?? [];

  const detailsArray = detailsInfo?.split(/\r|\n/);

  const shouldRenderTabs = () => {
    let count = 0;
    if (intro) {
      count++;
    }
    if (haveDocuments()) {
      count++;
    }
    if (detailsInfo) {
      count++;
    }
    return count > 1;
  };

  useEffect(() => {
    // Revalidate only when tab 2 (documents tab) is active
    if (activeTab === 2) {
      revalidateDocuments();
    }
  }, [activeTab, revalidateDocuments]);

  const renderTabs = () => {
    if (!shouldRenderTabs()) return null;
    return (
      <Tabs>
        {intro && (
          <Tab $active={activeTab === 1} onClick={() => setActiveTab(1)}>
            <Paragraph styleType="body14"> {overviewLabel}</Paragraph>
          </Tab>
        )}
        {haveDocuments() && (
          <Tab $active={activeTab === 2} onClick={() => setActiveTab(2)}>
            <Paragraph styleType="body14">{documentsLabel}</Paragraph>
          </Tab>
        )}
        {detailsInfo && (
          <Tab $active={activeTab === 3} onClick={() => setActiveTab(3)}>
            <Paragraph styleType="body14">{detailsLabel}</Paragraph>
          </Tab>
        )}
      </Tabs>
    );
  };

  const haveDocuments = () => {
    return documents && documents.length > 0;
  };
  const renderDocuments = () => {
    return documents.map((document, i) => {
      return (
        <DownloadFileBox
          key={`document${i}`}
          url={document.url}
          label={document.name}
          size={document.size}
          filetype={document.filetype}
        />
      );
    });
  };

  const renderTabInfo = () => {
    return (
      <>
        {intro && activeTab === 1 && <OverviewText>{intro}</OverviewText>}
        {haveDocuments() && activeTab === 2 && renderDocuments()}

        {detailsArray && detailsArray.length && activeTab === 3 && (
          <DetailInfo>
            {detailsArray.map((item, index) => (
              <li key={`Detail-Key-${index}`}>{item}</li>
            ))}
          </DetailInfo>
        )}
      </>
    );
  };

  return (
    <ProductInformationTabsStyled>
      {renderTabs()}
      {renderTabInfo()}
    </ProductInformationTabsStyled>
  );
};
