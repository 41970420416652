import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hellberg/helpers';

export const StyledProductVideo = styled.div`
  margin-inline: auto;
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.tablet}) {
    inline-size: 90%;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    inline-size: 65%;
    max-inline-size: 800px;
    padding-inline: ${spacing.medium};
    padding-block: var(--header-height) ${spacing.large};
  }
`;
