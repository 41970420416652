import Markdown from 'markdown-to-jsx';

import { HTag } from '../HTag/HTag';

import { StyledBulletList } from './BulletList.styled';
interface BulletListProps {
  title?: string;
  body: string;
  isBig: boolean;
  scrollId?: string | null | undefined;
}
export const BulletList: React.FC<BulletListProps> = ({
  title,
  body,
  isBig,
  scrollId,
}) => {
  return (
    <StyledBulletList $isBig={isBig} {...(scrollId ? { id: scrollId } : {})}>
      <div className="content">
        {title && (
          <HTag type="h2" styleType={isBig ? 'header3' : 'header4'}>
            {title}
          </HTag>
        )}

        <Markdown
          options={{ forceBlock: true }}
          className={isBig ? 'markdown-wrapper big' : 'markdown-wrapper'}
        >
          {body}
        </Markdown>
      </div>
    </StyledBulletList>
  );
};
