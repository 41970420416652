export const lineHeights = {
  body10: '16px',
  body12: '18px',
  body14: '20px',
  body16: '24px',
  header1: '46px',
  header2: '40px',
  header3: '32px',
  header4: '26px',
  l: 1.7,

  m: 1.5,

  mega: '66px',

  s: 1.25,
  // old and should be replaced
  xs: 1,
} as const;

export type LineHeight = keyof typeof lineHeights;
