import NextImage from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import {
  ImageFragment,
  TwoImageBlockFragment,
} from '@hultafors/hellberg/types';

import { DualImageBlockStyled, Inner, Wrapper } from './DualImageBlock.styled';

export const DualImageBlock: React.FC<TwoImageBlockFragment> = ({
  images,
  scrollId,
}) => {
  const sizes = [
    '(min-width: 1040px) 476px',
    `(min-width: ${breakpoints.mobileMax}) calc(50vw - 44px)`,
    'calc(100vw - 40px)',
  ].join(', ');

  function imageMapper(image: ImageFragment, i: number) {
    if (image?.url?.endsWith('.svg')) {
      return (
        <Inner key={`Image-${i}`} $aspectRatio="1 / 1">
          <NextImage
            src={image.url}
            alt={image.alt || 'image'}
            fill
            sizes={sizes}
            style={{ objectFit: 'cover' }}
          />
        </Inner>
      );
    }
    if (!image?.responsiveImage?.src) {
      return null;
    }
    return (
      <Inner
        key={`Image-${i}`}
        $aspectRatio={image?.responsiveImage?.aspectRatio}
      >
        <NextImage
          src={image.responsiveImage.src}
          alt={image.alt || 'image'}
          fill
          sizes={sizes}
          style={{ objectFit: 'cover' }}
          blurDataURL={image.responsiveImage.base64 || ''}
          placeholder="blur"
        />
      </Inner>
    );
  }

  return (
    <DualImageBlockStyled {...(scrollId ? { id: scrollId } : {})}>
      <Wrapper>{images.map(imageMapper)}</Wrapper>
    </DualImageBlockStyled>
  );
};
