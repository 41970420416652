import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

export const SearchBoxStyled = styled.span`
  width: 100%;
  max-width: 350px;
`;

interface ResultProps {
  $show?: boolean;
}

export const Result = styled.div<ResultProps>`
  position: absolute;
  display: block;
  display: flex;
  width: 100%;
  inset-block-start: 75px;
  inset-inline-start: 0;
  padding-block: 0;
  padding-inline: ${spacing.small};
  background-color: ${colors.white};
  z-index: 4;
  text-align: start;
  flex-direction: column;
  align-items: center;
  transform: translateY(104px);
  transition: transform 0.3s ease-in-out;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-block: 0;
    padding-inline: ${spacing.regular};
    inset-block-start: 0;
    margin-block-start: ${spacing.medium};
  }

  h3 {
    margin-block-end: ${spacing.xsmall};
  }

  button {
    margin-block-end: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.desktopMedium}) {
      margin-block-start: ${spacing.xsmall};
    }
  }
`;
