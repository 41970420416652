export const fontSizes = {
  body10: '10px',
  body12: '12px',
  body14: '14px',
  body16: '16px',
  h1: '3rem',
  h2: '2.5rem',
  h3: '2rem',
  h4: '1.25rem',
  header1: '40px',

  header2: '34px',

  header3: '26px',

  header4: '20px',

  l: '1.25rem',

  m: '1rem',

  mega: '60px',
  // old should be replaced
  microcopy: '0.625rem',
  s: '0.75rem',
} as const;

export type FontSize = keyof typeof fontSizes;
