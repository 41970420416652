import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { HTag } from '../HTag/HTag';

export const SeoSectionStyled = styled.div`
  border-block-start: 1px solid ${colors.gray4};
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: ${spacing.large};
    padding-inline: 0;
  }

  a,
  p {
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};
  }
`;

export const BottomSpacingStyled = styled.div`
  margin-block-end: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: ${spacing.large};
  }
`;

export const Title = styled(HTag).attrs({
  styleType: 'header2',
  type: 'h2',
})`
  padding-block-end: ${spacing.medium};
`;
