import styled, { css } from 'styled-components';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

interface LinkBlockStyledProps {
  $square?: boolean;
}

export const LinkBlockStyled = styled.div<LinkBlockStyledProps>`
  display: flex;
  justify-content: center;
  margin-block: ${spacing.medium};

  a {
    padding-block: 0;
    padding-inline: ${spacing.regular};
    block-size: 40px;
    background-color: ${colors.black};
    border-radius: 100px;
    color: ${colors.gray5};
    font-size: ${fontSizes.body14};
    line-height: ${lineHeights.body14};
    font-weight: ${fontWeights.fontMedium};
    font-family: ${fontFamilies.fontRegularBold};

    &:hover {
      background-color: ${colors.blackHover};
      color: ${colors.gray5};
    }
  }

  ${({ $square }) =>
    $square
    && css`
      a {
        border-radius: 0;
        block-size: 56px;
        padding-block: 0;
        padding-inline: 32px;
      }
    `}
`;
