import { useEffect, useMemo, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import { useDebounce, useKey } from 'react-use';
import useSWR from 'swr';

import { ProductListResponse } from '@hultafors/shared/types';

import { PAGE_SIZE_S, routes } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';
import { HellbergProduct } from '@hultafors/hellberg/types';

import { H2 } from '../H2/H2';
import { Loader } from '../Loader/Loader';
import { Products } from '../Products/Products';
import { ProductsGrid } from '../ProductsGrid/ProductsGrid';
import { SearchInput } from '../SearchInput/SearchInput';
import { TextButton } from '../TextButton/TextButton';

import { Result, SearchBoxStyled } from './SearchBox.styled';

export interface SearchBoxProps {
  isAutoComplete?: boolean;
  isOpen?: boolean;
  placeholder?: string;
  toggleMenu?: () => void;
  passive?: boolean;
  inMobileMenu?: boolean;
  initialValue?: string;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  isOpen,
  toggleMenu,
  placeholder,
  passive,
  initialValue = '',
}) => {
  const { shared, siteLocale } = useGlobal();
  const [term, setTerm] = useState(initialValue);
  const [searchValue, setSearchValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const [, cancel] = useDebounce(
    () => {
      setSearchValue(term);
    },
    300,
    [term]
  );

  const { locale, ...router } = useRouter();

  const { data, isValidating } = useSWR<ProductListResponse<HellbergProduct>>(
    !passive &&
      searchValue &&
      `/api/searchlist?${new URLSearchParams({
        includeFilters: 'false',
        lang: siteLocale.parttrapLanguage ?? '',
        market: siteLocale.parttrapMarket ?? '',
        pageSize: `${PAGE_SIZE_S}`,
        searchValue,
        siteId: siteLocale.parttrapSiteId ?? '',
      }).toString()}`,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
    }
  );
  const items = useMemo(() => data?.items || [], [data]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setTerm(event.target.value);
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    if (term.length) {
      cancel();
      const query = new URLSearchParams({ q: term }).toString();
      router.push(`/${routes.SEARCH}?${query}`);

      clearSearch();
      toggleMenu?.();
    }
  };

  const clearSearch = () => {
    setTerm('');
    setSearchValue('');
    cancel();
  };

  useKey('Escape', clearSearch);

  useEffect(() => {
    if (!isOpen) {
      clearSearch();
    }
  }, [isOpen]);

  return (
    <SearchBoxStyled>
      <SearchInput
        onSubmit={onSubmit}
        onChange={onChange}
        onClear={clearSearch}
        placeholder={placeholder || shared?.searchProducts || ''}
        value={term}
      />

      <Result>
        {isValidating && <Loader />}
        {!isValidating && items.length > 0 && (
          <>
            <H2>{shared.suggestions}</H2>
            <TextButton
              onClick={onSubmit}
              iconUrl="/assets/gfx/arrow-right.svg"
            >
              {shared.viewAll}
            </TextButton>

            <ProductsGrid>
              <Products
                products={items}
                rrpLabel={shared.rrpLabel || ''}
                rrpIncludingVatLabel={shared.rrpIncludingVatLabel || ''}
                toggleMenu={toggleMenu}
              />
            </ProductsGrid>
          </>
        )}
      </Result>
    </SearchBoxStyled>
  );
};
