import { useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import { Swiper } from 'swiper';
import {
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination,
} from 'swiper/modules';

import { breakpoints } from '@hultafors/shared/constants';
import { ParttrapImage } from '@hultafors/shared/types';

import { colors } from '@hultafors/hellberg/helpers';
import { useGlobal } from '@hultafors/hellberg/hooks';

import ChevronDown from '../../svg/chevron_down.svg';
import Plus from '../../svg/plus.svg';
import { ProductImageDialog } from '../ProductImageDialog/ProductImageDialog';
import { TextButton } from '../TextButton/TextButton';

import {
  Container,
  NavigationButton,
  Pagination,
  SwiperContainer,
  SwiperSlide,
  SwiperWrapper,
  Thumbnail,
  ThumbNailWrapper,
  ZoomContainer,
} from './ProductSlider.styled';

require('swiper/css');
require('swiper/css/navigation');

interface ProductSliderProps {
  images?: ParttrapImage[];
  productName?: string;
}

export const ProductSlider: React.FC<ProductSliderProps> = ({
  images = [],
  productName,
}) => {
  const { shared } = useGlobal();
  const swiper = useRef<Swiper | null>(null);
  const swiperElement = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (swiperElement.current && !swiper.current) {
      swiper.current = new Swiper(swiperElement.current, {
        direction: 'horizontal',
        loop: true,
        modules: [SwiperNavigation, SwiperPagination],
        on: {
          realIndexChange: (swiper) => {
            setCurrentIndex(swiper.realIndex);
          },
        },
        pagination: {
          el: paginationRef.current,
          type: 'fraction',
        },
      });
    }
  }, []);

  const showDialog = () => {
    document.body.style.overflowY = 'hidden';
    setOpenDialog(true);
  };

  const closeDialog = () => {
    document.body.style.overflowY = 'initial';
    setOpenDialog(false);
  };

  const sizes = [
    `(min-width: ${breakpoints.desktopLarge}) 692px`,
    `(min-width: ${breakpoints.desktopSmall}) calc((100vw - 220px) * 7 / 12 - 20px)`,
    '100vw',
  ].join(', ');
  function slideMapper(image: ParttrapImage, index: number) {
    if (!image.url) {
      return null;
    }
    return (
      <SwiperSlide
        key={`ProductSlider-image-${index}`}
        className="swiper-slide"
      >
        <Image
          src={`${image.url}?w=2880&format=jpg&bgcolor=${colors.gray5.substring(
            1
          )}`}
          fill
          alt={
            image.description || productName
              ? `${productName} - ${index + 1}`
              : ''
          }
          sizes={sizes}
          priority={index === 0}
          loading={index === 0 ? 'eager' : 'lazy'}
        />
      </SwiperSlide>
    );
  }

  function thumbnailMapper(image: ParttrapImage, index: number) {
    function onClick() {
      if (!swiper.current) {
        return;
      }
      if (
        index === swiper.current.realIndex + 1 ||
        (index === 0 && swiper.current.realIndex === images.length - 1)
      ) {
        swiper.current.slideNext();
      } else if (
        index === swiper.current?.realIndex - 1 ||
        (index === images.length - 1 && swiper.current.realIndex === 0)
      ) {
        swiper.current.slidePrev();
      } else if (swiper.current.realIndex !== swiper.current.activeIndex) {
        swiper.current.slideToLoop(index);
      } else {
        swiper.current.slideTo(index);
      }
    }
    return (
      <Thumbnail
        key={`productItem${index}`}
        $active={currentIndex === index}
        onClick={onClick}
      >
        <Image
          src={`${image.url}?w=2880&format=jpg&bgcolor=${colors.gray5.substring(
            1
          )}`}
          alt={
            image.description || productName
              ? `${productName} - ${index + 1}`
              : ''
          }
          width={100}
          height={100}
        />
      </Thumbnail>
    );
  }

  function slidePrev() {
    swiper.current?.slidePrev();
  }
  function slideNext() {
    swiper.current?.slideNext();
  }

  return (
    <Container>
      {openDialog && (
        <ProductImageDialog images={images} onClick={closeDialog} />
      )}
      <ThumbNailWrapper>{images.map(thumbnailMapper)}</ThumbNailWrapper>
      <SwiperContainer className="swiper" ref={swiperElement}>
        <ZoomContainer>
          <TextButton onClick={showDialog} aria-label={shared.zoomImage}>
            <Plus aria-hidden={true} focusable={false} width={16} height={16} />
          </TextButton>
        </ZoomContainer>
        <SwiperWrapper className="swiper-wrapper">
          {images.map(slideMapper)}
        </SwiperWrapper>

        <NavigationButton
          ref={prevRef}
          aria-label={shared.previous || ''}
          onClick={slidePrev}
          $flip
        >
          <ChevronDown
            aria-hidden={true}
            focusable={false}
            width={18}
            height={18}
          />
        </NavigationButton>

        <Pagination ref={paginationRef} />

        <NavigationButton
          ref={nextRef}
          aria-label={shared.next || ''}
          onClick={slideNext}
        >
          <ChevronDown
            aria-hidden={true}
            focusable={false}
            width={18}
            height={18}
          />
        </NavigationButton>
      </SwiperContainer>
    </Container>
  );
};
