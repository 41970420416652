import Markdown from 'markdown-to-jsx';

import { TextBlockFragment } from '@hultafors/hellberg/types';

import { HTag } from '../HTag/HTag';

import { TextBlockStyled } from './TextBlock.styled';

interface TextBlockProps extends TextBlockFragment {
  className?: string;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  title,
  body,
  bigHeader,
  className,
  scrollId,
}) => {
  return (
    <TextBlockStyled
      className={className}
      $hasMarkDown={!!body}
      {...(scrollId ? { id: scrollId } : {})}
    >
      {title && (
        <HTag
          type={bigHeader ? 'h2' : 'h3'}
          styleType={bigHeader ? 'header3' : 'header4'}
        >
          {title}
        </HTag>
      )}
      <Markdown options={{ forceBlock: true }} className="markdown-wrapper">
        {body ?? ''}
      </Markdown>
    </TextBlockStyled>
  );
};
