import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

import { HTag } from '../HTag/HTag';
import { TextBlock } from '../TextBlock/TextBlock';

export const BannerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray4};
  width: 100%;
  min-height: 64px;
  margin-block: ${spacing.xxsmall};
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  max-inline-size: 1280px;
  align-items: center;
  padding-block: ${spacing.small};
  padding-inline: ${spacing.medium};
  gap: 50px;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-inline: ${spacing.medium};
    flex-direction: row;
  }
`;

export const DownloadAppsContainer = styled.div<{ $aspectRatio?: string }>`
  display: flex;
  flex-direction: column;
  inline-size: 100%;

  > div {
    inline-size: 100%;
    margin-inline: 0;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-block: 48px;
  }
`;

export const ImageWrapper = styled.div<{ $aspectRatio?: string }>`
  position: relative;
  aspect-ratio: ${({ $aspectRatio }) => ($aspectRatio ? $aspectRatio : '1')};
  inline-size: 100%;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    max-block-size: 400px;
  }
`;

export const DownloadLabel = styled(HTag).attrs({
  type: 'h3',
})`
  margin-block: ${spacing.block} ${spacing.small};
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
`;

export const BadgesContainer = styled.div`
  display: flex;
  gap: 8px;
  max-inline-size: 300px;
`;

export const AppLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: none;
  }
`;

export const TextContent = styled(TextBlock)`
  max-inline-size: 100%;
  margin-block: 0;

  :first-child {
    margin-block-start: 0;
  }

  h3 {
    font-size: 28px;
    margin-block-end: 24px;
  }

  p {
    max-inline-size: 570px;
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
  }
`;

export const AppBtn = styled.button`
  position: relative;
  display: none;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  inline-size: 100%;
  block-size: 100%;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
`;

export const PopupContent = styled.div`
  min-height: 50px;
  min-width: 200px;
  display: flex;
  inline-size: 1000px;
  max-inline-size: 600px;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-block: 48px;
  padding-inline: 48px;
  background-color: ${colors.gray4};

  > div {
    margin: 0;
    inline-size: 100%;
  }

  h2,
  h3 {
    font-size: 32px;
    line-height: normal;
    font-weight: 700;
  }

  h2:first-child,
  h3:first-child {
    margin: 0;
    margin-block-end: ${spacing.small};
  }

  p {
    font-size: 16px;
  }
`;

export const PopupImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
  max-inline-size: 150px;
  max-block-size: 150px;
`;
