import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment } from '@hultafors/hellberg/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';

import { Description, StyledInformationHero } from './InformationHero.styled';

interface InformationHeroProps {
  title: string;
  description: string;
  image?: ImageFragment;
  mobileImage?: ImageFragment;
  className?: string;
  center?: boolean;
}

export const InformationHero: React.FC<InformationHeroProps> = ({
  title,
  image,
  mobileImage,
  description,
  className,
  center,
}) => {
  if (!title) {
    return null;
  }
  return (
    title &&
    description && (
      <StyledInformationHero
        $mobileImage={!!mobileImage}
        $image={!!image}
        $center={center}
        className={className}
      >
        <Grid
          columnGap={center ? 0 : 48}
          maxWidth={center ? breakpoints.maxPageWidth : '3000px'}
        >
          <GridChild
            columnSpan={[
              { columns: 4 },
              {
                breakpoint: 'mobileMax',
                columns: center ? 8 : 12,
                start: center ? 3 : 1,
              },
              {
                breakpoint: 'desktop',
                columns: center ? 6 : 5,
                start: center ? 4 : 1,
              },
              {
                breakpoint: 'desktopXL',
                columns: center ? 6 : 4,
                start: center ? 4 : 1,
              },
            ]}
          >
            <div className="HeroText">
              {title && (
                <HTag
                  type="h1"
                  styleType={[
                    { styleType: 'header3' },
                    { breakpoint: 'mobileMax', styleType: 'header2' },
                  ]}
                >
                  {title}
                </HTag>
              )}
              {description && (
                <Markdown
                  options={{
                    forceBlock: true,
                    overrides: { span: Description },
                  }}
                >
                  {description}
                </Markdown>
              )}
            </div>
          </GridChild>

          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 12, start: 1 },
              { breakpoint: 'desktop', columns: 6, start: 7 },
            ]}
          >
            {image && !center && (
              <Image
                className="DesktopImage"
                src={image.responsiveImage?.src ?? ''}
                blurDataURL={image.responsiveImage?.base64 ?? ''}
                alt={image?.alt ?? ''}
                placeholder="blur"
                priority={true}
                sizes="50vw"
                fill
              />
            )}
            {mobileImage && !center && (
              <Image
                className="MobileImage"
                src={mobileImage.responsiveImage?.src ?? ''}
                blurDataURL={mobileImage.responsiveImage?.base64 ?? ''}
                alt={mobileImage?.alt ?? ''}
                placeholder="blur"
                priority={true}
                sizes="50vw"
                fill
              />
            )}
          </GridChild>
        </Grid>
      </StyledInformationHero>
    )
  );
};
