export const colors = {
  black: '#111111',
  blackHover: '#2D2D2D',
  gray0: '#74747f',
  gray1: '#90909B',
  gray2: '#B3B4BC',

  gray3: '#DEDEDE',

  gray4: '#EAEAEA',

  gray5: '#F8F8FC',

  gray6: '#F7F7F7',

  // Not uses
  hellActive: '#5686FF',

  hellBlue: '#0000FF',

  hellBlueHover: '#5F5FFF',

  hellNo: '#FF1E1E',

  hellSalmon: '#FA826E',

  hellSalmonHover: '#EA7966',

  // Not used
  hellSalmonLight: '#FCC0B7',

  // Not used
  hellTurqouise: '#00FFC8',

  hellTurqouiseHover: '#07E2B3',

  // Not used
  hellTurqouiseLight: '#80FFE3',

  // Not uses
  hellYeah: '#67FC6D',

  // Not used
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',

  white: '#FFFFFF',
  // Not used
  yellow: 'rgba(242, 201, 76, 1)', // Not used
} as const;

export type Color = keyof typeof colors;
