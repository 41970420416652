export const spacing = {
  XL: '192px',
  block: '32px',
  large: '96px',
  medium: '48px',
  menuHeight: '64px',
  // Not sure is this are accurate
  mobileTop: '72px',

  regular: '24px',

  small: '16px',

  tinier: '8px',

  tiny: '8px',

  xsmall: '8px',

  xxsmall: '4px',
} as const;

export type Spacing = keyof typeof spacing;
