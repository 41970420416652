import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hellberg/helpers';

interface StyledHeroProps {
  $image?: boolean;
  $borderColor?: CSSProperties['borderColor'];
  $color?: CSSProperties['color'];
}

export const StyledHero = styled.div<StyledHeroProps>`
  position: relative;
  height: auto;
  width: 100%;
  z-index: 1;
  aspect-ratio: 3 / 4;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 1 / 1;
    min-block-size: auto;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 12 / 5;
  }

  .Title {
    color: ${colors.white};
    text-align: center;
    text-shadow: 0 2px 20px rgb(0 0 0 / 25%);
  }

  .HeroContent {
    position: relative;
    block-size: 100%;
    inline-size: 100%;
    padding: ${spacing.medium};
  }

  .CenterWrapper {
    inline-size: 100%;
    position: relative;
  }

  .HeroButtonWrapper {
    inline-size: 100%;

    a {
      font-size: ${fontSizes.body14};
      line-height: ${lineHeights.body14};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegularBold};
      overflow: hidden;
      white-space: nowrap;

      &:first-child {
        margin-block-end: ${spacing.small};
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-start: ${spacing.medium};
    text-align: center;
    ${({ $image }) =>
      !$image
      && css`
        a {
          border: 1px solid ${colors.black};
          color: ${colors.black};

          &:hover {
            background-color: ${colors.black};
            color: ${colors.white};
          }
        }
      `}

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-start: ${spacing.medium};
    }
  }

  .InnerButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-inline-size: 120px;
    max-inline-size: 100%;
  }
`;

export const ContentWrapper = styled.div<StyledHeroProps>`
  border: ${({ $borderColor }) =>
    $borderColor ? `5px solid ${$borderColor}` : 'none'};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeroIcon = styled.div<StyledHeroProps>`
  color: ${({ $color = 'inherit' }) => $color};
  display: flex;
  justify-content: center;
  width: 100%;
`;
