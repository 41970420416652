import dynamic from 'next/dynamic';

import { StyledProductVideo } from './ProductVideo.styled';

const BynderVideo = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.BynderVideo),
);

interface ProductVideoProps {
  videoId: string;
}

export const ProductVideo: React.FC<ProductVideoProps> = ({ videoId }) => {
  return (
    <StyledProductVideo>
      <BynderVideo videoId={videoId} />
    </StyledProductVideo>
  );
};
