import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hellberg/helpers';

import { TextButton } from '../TextButton/TextButton';

export const NavigationDesktopStyled = styled.div`
  display: none;
  height: 100%;
  margin-block: 0;
  margin-inline: auto;
  max-width: ${breakpoints.maxPageWidth};
  justify-content: space-between;
  align-items: flex-end;
  padding-block-end: ${spacing.tiny};

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: flex;
  }

  .Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: ${spacing.small} ${spacing.tiny};
    color: ${colors.black};
  }

  .LogoTopMenuWrapper {
    display: flex;
    gap: ${spacing.medium};
  }

  .TextButton {
    padding: 0;
  }
`;

export const TopMenu = styled.div`
  display: flex;
  align-self: flex-end;
  gap: ${spacing.small};
  box-sizing: border-box;

  > div {
    position: absolute;
  }
`;
interface ActiveProps {
  $active?: boolean;
}
const sharedTopMenuitemStyle = css<ActiveProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-inline: 0;
  padding-block: ${spacing.small} ${spacing.tiny};

  &:hover {
    p {
      color: ${colors.hellBlue};
    }

    &::after {
      content: '';
      inline-size: 100%;
      position: absolute;
      inset: 0;
      inset-block-end: -9px;
      border-block-end: thin solid ${colors.hellBlue};
    }
  }
`;

export const TopMenuButton = styled(TextButton)<ActiveProps>`
  ${sharedTopMenuitemStyle};
`;

export const TopMenuLink = styled(Link)<ActiveProps>`
  ${sharedTopMenuitemStyle};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopActions = styled.div`
  display: flex;
  gap: ${spacing.small};

  > div,
  > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: auto;
    block-size: 100%;
    cursor: pointer;

    svg,
    a {
      line-height: 0;
      block-size: ${spacing.regular};
    }

    &.Partner:hover {
      svg path {
        stroke: ${colors.blackHover};
      }
    }

    &.Favorite:hover {
      svg path {
        stroke: ${colors.blackHover};
      }
    }

    &.Search:hover {
      svg path {
        stroke: ${colors.blackHover};
      }
    }
  }
`;
