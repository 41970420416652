import { IFrameWrapper } from './IFrameContainer.styled';

export type IFrameContainerProps =
  React.IframeHTMLAttributes<HTMLIFrameElement> & {
    scrollId?: string | null | undefined;
  };

export const IFrameContainer: React.FC<IFrameContainerProps> = ({
  title,
  src,
  height = '100%',
  width = '100%',
  scrollId,
  ...rest
}) => {
  return (
    <IFrameWrapper
      $width={width}
      $height={height}
      {...(scrollId ? { id: scrollId } : {})}
    >
      <iframe title={title} src={src} width={width} height={height} {...rest} />
    </IFrameWrapper>
  );
};
