import styled, { css } from 'styled-components';

import { buttonReset } from '@hultafors/shared/helpers';

import { spacing } from '@hultafors/hellberg/helpers';

export const Container = styled.div`
  position: relative;

  .swiper {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    gap: ${spacing.xsmall};
  }

  .swiper-slide {
    position: relative;

    img {
      object-fit: contain;
    }
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xsmall};
`;

export const NavigationButton = styled.button<{ $flip?: boolean }>`
  ${buttonReset};
  cursor: pointer;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $flip }) =>
    $flip
    && css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
